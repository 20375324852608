//export const BASE_URL = "https://dev-api.thedal.app/";
//export const BASE_URL = "https://api.thedal.app/";
export const BASE_URL = "https://dev-api.thedal.app/";
//export const BASE_URL = "http://localhost:8000/";

export const API_URL = `${BASE_URL}api`;

export const END_POINT = {
  LOGIN: `${API_URL}/login`,
  MENUS: `${API_URL}/menus`,
  MODULES: `${API_URL}/modules`,
  FORGOT_PASSWORD: `${API_URL}/forgot-password`,

  DASHBOARD: `${API_URL}/dashboard`,
  ELECTION_DASHBOARD: `${API_URL}/dashboard/election`,

  RESET_PASSWORD: `${API_URL}/profile/edit/password`,
  PROFILE_UPDATE: `${API_URL}/profile/edit`,

  SETTINGS: `${API_URL}/system/settings`,
  SETTING_CREATE: `${API_URL}/system/settings/create`,
  SETTING_EDIT: `${API_URL}/system/settings/edit/`,
  SETTING_DELETE: `${API_URL}/system/settings/delete`,
  LOG_FILE_EXPORT: `${API_URL}/system/settings/download-log`,

  MENU_ITEMS: `${API_URL}/system/menu-items`,
  MENU_ITEM_LIST: `${API_URL}/system/menu-items/list`,
  MENU_ITEMS_CREATE: `${API_URL}/system/menu-items/create`,
  MENU_ITEMS_EDIT: `${API_URL}/system/menu-items/edit/`,
  MENU_ITEMS_DELETE: `${API_URL}/system/menu-items/delete`,
  MENU_ITEMS_STATUS_TOGGLE: `${API_URL}/system/menu-items/status-toggle`,
  SUB_MENU_ITEM_LIST: `${API_URL}/system/menu-items/sub/list/`,

  USERS: `${API_URL}/user-administration/users`,
  USER_CREATE: `${API_URL}/user-administration/users/create`,
  USER_EDIT: `${API_URL}/user-administration/users/edit/`,
  USER_DELETE: `${API_URL}/user-administration/users/delete`,
  USER_STATUS_TOGGLE: `${API_URL}/user-administration/users/status-toggle`,
  ALL_USERS: `${API_URL}/user-administration/users/list`,
  USER_SET_CURRENT_ELECTION: `${API_URL}/user/set-current-election`,
  USERS_STATE_LIST: `${API_URL}/user-administration/users/user-state-list/`,
  USERS_DISTRICT_LIST: `${API_URL}/user-administration/users/user-district-list/`,
  USERS_PC_LIST: `${API_URL}/user-administration/users/user-pc-list/`,
  USERS_AC_LIST: `${API_URL}/user-administration/users/user-ac-list/`,
  USERS_AC_BOOTH_LIST: `${API_URL}/user-administration/users/user-ac-booth-list/`,
  USER_IMAGE_UPLOAD: `${API_URL}/user-administration/users/upload-image`,
  USER_CLIENT_LIST: `${API_URL}/user-administration/users/list/users-by-clientId/`,
  USERS_LIST: `${API_URL}/user/list`,
  USER_ROLE_ID_EDIT: `${API_URL}/user-administration/users/update-role/`,

  USERS_LOG: `${API_URL}/user-administration/user-log`,
  USERS_LOG_DELETE: `${API_URL}/user-administration/user-log/delete`,
  USERS_LOG_BY_ID_LIST: `${API_URL}/user-administration/user-log/list/`,

  All_ROLES: `${API_URL}/user-administration/roles/all`,
  ROLES: `${API_URL}/user-administration/roles`,
  ROLE_CREATE: `${API_URL}/user-administration/roles/create`,
  ROLE_EDIT: `${API_URL}/user-administration/roles/edit`,
  ROLE_DELETE: `${API_URL}/user-administration/roles/delete`,

  CLIENT: `${API_URL}/user-administration/client`,
  CLIENT_CREATE: `${API_URL}/user-administration/client/create`,
  CLIENT_EDIT: `${API_URL}/user-administration/client/edit/`,
  CLIENT_DELETE: `${API_URL}/user-administration/client/delete`,
  CLIENT_LIST: `${API_URL}/user-administration/client/list`,

  ALL_MENUS: `${API_URL}/system/menu-items/get-all`,

  EMAIL_TEMPLATE_LIST: `${API_URL}/system/email-template`,
  EMAIL_TEMPLATE_CREATE: `${API_URL}/system/email-template/create`,
  EMAIL_TEMPLATE_EDIT: `${API_URL}/system/email-template/edit/`,
  EMAIL_TEMPLATE_DELETE: `${API_URL}/system/email-template/delete`,
  EMAIL_TEMPLATE_UPLOAD: `${API_URL}/system/email-template/upload-template`,
  EMAIL_TEMPLATE_STATUS_TOGGLE: `${API_URL}/system/email-template/status-toggle`,
  EMAIL_TEMPLATE_DEFAULT_TOGGLE: `${API_URL}/system/email-template/default-toggle`,

  BLOCKED_IP_LIST: `${API_URL}/system/blocked-ip`,
  BLOCKED_IP_CREATE: `${API_URL}/system/blocked-ip/create`,
  BLOCKED_IP_EDIT: `${API_URL}/system/blocked-ip/edit/`,
  BLOCKED_IP_DELETE: `${API_URL}/system/blocked-ip/delete`,

  SMS_TEMPLATE_LIST: `${API_URL}/system/sms-template`,
  SMS_TEMPLATE_CREATE: `${API_URL}/system/sms-template/create`,
  SMS_TEMPLATE_EDIT: `${API_URL}/system/sms-template/edit/`,
  SMS_TEMPLATE_DELETE: `${API_URL}/system/sms-template/delete`,
  SMS_TEMPLATE_STATUS_TOGGLE: `${API_URL}/system/sms-template/status-toggle`,

  DATABASE_BACKUP_LIST: `${API_URL}/system/database-backup`,
  DATABASE_BACKUP_CREATE: `${API_URL}/system/database-backup/create`,
  DATABASE_BACKUP_DELETE: `${API_URL}/system/database-backup/delete`,
  DATABASE_BACKUP_EXPORT: `${API_URL}/system/database-backup/export/file`,

  MODULES_LIST: `${API_URL}/system/modules`,
  MODULES_CREATE: `${API_URL}/system/modules/create`,
  MODULES_EDIT: `${API_URL}/system/modules/edit/`,
  MODULES_DELETE: `${API_URL}/system/modules/delete`,
  MODULES_STATUS_TOGGLE: `${API_URL}/system/modules/status-toggle`,
  MODULES_ALL_LIST: `${API_URL}/system/modules/list`,

  SENT_EMAIL_LIST: `${API_URL}/reports/sent-email`,
  SENT_EMAIL_DELETE: `${API_URL}/reports/sent-email/delete`,
  SENT_EMAIL_STATUS_TOGGLE: `${API_URL}/reports/sent-email/status-toggle`,

  SENT_SMS_LIST: `${API_URL}/reports/sent-sms`,
  SENT_SMS_DELETE: `${API_URL}/reports/sent-sms/delete`,
  SENT_SMS_STATUS_TOGGLE: `${API_URL}/reports/sent-sms/status-toggle`,

  NOTIFICATION_LIST: `${API_URL}/user-administration/notification`,
  NOTIFICATION_CREATE: `${API_URL}/user-administration/notification/create`,
  NOTIFICATION_EDIT: `${API_URL}/user-administration/notification/edit/`,
  NOTIFICATION_DELETE: `${API_URL}/user-administration/notification/delete`,
  NOTIFICATION_STATUS_TOGGLE: `${API_URL}/user-administration/notification/status-toggle`,
  NOTIFICATION_LIST_COUNT: `${API_URL}/user-administration/notification/get/notification`,
  NOTIFICATION_LIST_COUNT_UPDATE: `${API_URL}/user-administration/notification/app/edit/`,

  FAVORITE_MENU_LIST: `${API_URL}/system/favorite-menu/app/list`,
  FAVORITE_MENU_CREATE: `${API_URL}/system/favorite-menu/app/create`,
  FAVORITE_MENU_DELETE: `${API_URL}/system/favorite-menu/app/delete`,

  STATES_LIST: `${API_URL}/master/states`,
  STATES_CREATE: `${API_URL}/master/states/create`,
  STATES_EDIT: `${API_URL}/master/states/edit/`,
  STATES_DELETE: `${API_URL}/master/states/delete`,
  STATES_UPLOAD_FILE: `${API_URL}/master/states/upload-state`,
  STATES_READ_FILE: `${API_URL}/master/states/read-state`,
  STATES_ALL_LIST: `${API_URL}/master/states/list`,
  STATES_EXPORT: `${API_URL}/master/states/export-csv`,
  MASTER_FILE_UPLOAD: `${API_URL}/master/states/upload/file`,

  DISTRICTS_LIST: `${API_URL}/master/districts`,
  DISTRICTS_CREATE: `${API_URL}/master/districts/create`,
  DISTRICTS_EDIT: `${API_URL}/master/districts/edit/`,
  DISTRICTS_DELETE: `${API_URL}/master/districts/delete`,
  DISTRICTS_UPLOAD_FILE: `${API_URL}/master/districts/upload-district`,
  DISTRICTS_READ_FILE: `${API_URL}/master/districts/read-district`,
  DISTRICTS_ALL_LIST: `${API_URL}/master/districts/list`,
  DISTRICTS_STATE_DISTRICT_LIST: `${API_URL}/master/districts/district-list/`,
  DISTRICTS_EXPORT: `${API_URL}/master/districts/export-csv`,

  MASTER_PC_LIST: `${API_URL}/master/pc`,
  MASTER_PC_CREATE: `${API_URL}/master/pc/create`,
  MASTER_PC_EDIT: `${API_URL}/master/pc/edit/`,
  MASTER_PC_DELETE: `${API_URL}/master/pc/delete`,
  MASTER_PC_UPLOAD_FILE: `${API_URL}/master/pc/upload-pc`,
  MASTER_PC_READ_FILE: `${API_URL}/master/pc/read-pc`,
  MASTER_PC_ALL_LIST: `${API_URL}/master/pc/list`,
  MASTER_STATE_PC_LIST: `${API_URL}/master/pc/pc-list/`,
  MASTER_PC_EXPORT: `${API_URL}/master/pc/export-csv`,

  MASTER_AC_LIST: `${API_URL}/master/ac`,
  MASTER_AC_CREATE: `${API_URL}/master/ac/create`,
  MASTER_AC_EDIT: `${API_URL}/master/ac/edit/`,
  MASTER_AC_DELETE: `${API_URL}/master/ac/delete`,
  MASTER_AC_UPLOAD_FILE: `${API_URL}/master/ac/upload-ac`,
  MASTER_AC_READ_FILE: `${API_URL}/master/ac/read-ac`,
  MASTER_AC_ALL_LIST: `${API_URL}/master/ac/list`,
  MASTER_PC_AC_LIST: `${API_URL}/master/ac/ac-pc-list/`,
  MASTER_STATE_AC_LIST: `${API_URL}/master/ac/ac-state-list/`,
  MASTER_AC_EXPORT: `${API_URL}/master/ac/export-csv`,

  MASTER_CORPORATION_LIST: `${API_URL}/master-urban/corporation`,
  MASTER_CORPORATION_CREATE: `${API_URL}/master-urban/corporation/create`,
  MASTER_CORPORATION_EDIT: `${API_URL}/master-urban/corporation/edit/`,
  MASTER_CORPORATION_DELETE: `${API_URL}/master-urban/corporation/delete`,
  MASTER_CORPORATION_UPLOAD_FILE: `${API_URL}/master-urban/corporation/upload`,
  MASTER_CORPORATION_READ_FILE: `${API_URL}/master-urban/corporation/read`,
  MASTER_CORPORATION_ALL_LIST: `${API_URL}/master-urban/corporation/list`,
  MASTER_CORPORATION_EXPORT: `${API_URL}/master-urban/corporation/export-csv`,
  MASTER_CORPORATION_DISTRICT_LIST: `${API_URL}/master-urban/corporation/corporation-district-list/`,
  MASTER_CORPORATION_STATE_LIST: `${API_URL}/master-urban/corporation/corporation-state-list/`,

  MASTER_MUNICIPALITY_LIST: `${API_URL}/master-urban/municipality`,
  MASTER_MUNICIPALITY_CREATE: `${API_URL}/master-urban/municipality/create`,
  MASTER_MUNICIPALITY_EDIT: `${API_URL}/master-urban/municipality/edit/`,
  MASTER_MUNICIPALITY_DELETE: `${API_URL}/master-urban/municipality/delete`,
  MASTER_MUNICIPALITY_UPLOAD_FILE: `${API_URL}/master-urban/municipality/upload`,
  MASTER_MUNICIPALITY_READ_FILE: `${API_URL}/master-urban/municipality/read`,
  MASTER_MUNICIPALITY_ALL_LIST: `${API_URL}/master-urban/municipality/list`,
  MASTER_MUNICIPALITY_EXPORT: `${API_URL}/master-urban/municipality/export-csv`,
  MASTER_MUNICIPALITY_DISTRICT_LIST: `${API_URL}/master-urban/municipality/municipality-district-list/`,
  MASTER_MUNICIPALITY_STATE_LIST: `${API_URL}/master-urban/municipality/municipality-state-list/`,

  MASTER_TOWN_PANCHAYAT_LIST: `${API_URL}/master-urban/town-panchayat`,
  MASTER_TOWN_PANCHAYAT_CREATE: `${API_URL}/master-urban/town-panchayat/create`,
  MASTER_TOWN_PANCHAYAT_EDIT: `${API_URL}/master-urban/town-panchayat/edit/`,
  MASTER_TOWN_PANCHAYAT_DELETE: `${API_URL}/master-urban/town-panchayat/delete`,
  MASTER_TOWN_PANCHAYAT_UPLOAD_FILE: `${API_URL}/master-urban/town-panchayat/upload`,
  MASTER_TOWN_PANCHAYAT_READ_FILE: `${API_URL}/master-urban/town-panchayat/read`,
  MASTER_TOWN_PANCHAYAT_ALL_LIST: `${API_URL}/master-urban/town-panchayat/list`,
  MASTER_TOWN_PANCHAYAT_EXPORT: `${API_URL}/master-urban/town-panchayat/export-csv`,
  MASTER_TOWN_PANCHAYAT_DISTRICT_LIST: `${API_URL}/master-urban/town-panchayat/town-panchayat-district-list/`,
  MASTER_TOWN_PANCHAYAT_STATE_LIST: `${API_URL}/master-urban/town-panchayat/town-panchayat-state-list/`,

  MASTER_AC_URBAN_LIST: `${API_URL}/master-urban/ac-urban`,
  MASTER_AC_URBAN_CREATE: `${API_URL}/master-urban/ac-urban/create`,
  MASTER_AC_URBAN_EDIT: `${API_URL}/master-urban/ac-urban/edit/`,
  MASTER_AC_URBAN_DELETE: `${API_URL}/master-urban/ac-urban/delete`,
  MASTER_AC_URBAN_UPLOAD_FILE: `${API_URL}/master-urban/ac-urban/upload`,
  MASTER_AC_URBAN_READ_FILE: `${API_URL}/master-urban/ac-urban/read`,
  MASTER_AC_URBAN_ALL_LIST: `${API_URL}/master-urban/ac-urban/list`,
  MASTER_AC_URBAN_EXPORT: `${API_URL}/master-urban/ac-urban/export-csv`,

  MASTER_URBAN_WARD_LIST: `${API_URL}/master-urban/urban-ward`,
  MASTER_URBAN_WARD_CREATE: `${API_URL}/master-urban/urban-ward/create`,
  MASTER_URBAN_WARD_EDIT: `${API_URL}/master-urban/urban-ward/edit/`,
  MASTER_URBAN_WARD_DELETE: `${API_URL}/master-urban/urban-ward/delete`,
  MASTER_URBAN_WARD_UPLOAD_FILE: `${API_URL}/master-urban/urban-ward/upload`,
  MASTER_URBAN_WARD_READ_FILE: `${API_URL}/master-urban/urban-ward/read`,
  MASTER_URBAN_WARD_ALL_LIST: `${API_URL}/master-urban/urban-ward/list`,
  MASTER_URBAN_WARD_EXPORT: `${API_URL}/master-urban/urban-ward/export-csv`,
  MASTER_URBAN_WARD_CORPORATION_LIST: `${API_URL}/master-urban/urban-ward/corporation-ward-list`,
  MASTER_URBAN_WARD_MUNICIPALITY_LIST: `${API_URL}/master-urban/urban-ward/municipality-ward-list`,
  MASTER_URBAN_WARD_TOWN_PANCHAYAT_LIST: `${API_URL}/master-urban/urban-ward/town-panchayat-ward-list`,
  MASTER_URBAN_WARD_CREATE_DATA_DOWNLOAD_REQUEST: `${API_URL}/master-urban/urban-ward/create/data/download-request`,

  MASTER_DISTRICT_PANCHAYAT_UNION_LIST: `${API_URL}/master-rural/district-union`,
  MASTER_DISTRICT_PANCHAYAT_UNION_CREATE: `${API_URL}/master-rural/district-union/create`,
  MASTER_DISTRICT_PANCHAYAT_UNION_EDIT: `${API_URL}/master-rural/district-union/edit/`,
  MASTER_DISTRICT_PANCHAYAT_UNION_DELETE: `${API_URL}/master-rural/district-union/delete`,
  MASTER_DISTRICT_PANCHAYAT_UNION_UPLOAD_FILE: `${API_URL}/master-rural/district-union/upload`,
  MASTER_DISTRICT_PANCHAYAT_UNION_READ_FILE: `${API_URL}/master-rural/district-union/read`,
  MASTER_DISTRICT_PANCHAYAT_UNION_ALL_LIST: `${API_URL}/master-rural/district-union/list`,
  MASTER_DISTRICT_PANCHAYAT_UNION_EXPORT: `${API_URL}/master-rural/district-union/export-csv`,
  MASTER_DISTRICT_UNION_DISTRICT_LIST: `${API_URL}/master-rural/district-union/district/`,

  MASTER_DISTRICT_PANCHAYAT_UNION_WARD_LIST: `${API_URL}/master-rural/district-union-ward`,
  MASTER_DISTRICT_PANCHAYAT_UNION_WARD_CREATE: `${API_URL}/master-rural/district-union-ward/create`,
  MASTER_DISTRICT_PANCHAYAT_UNION_WARD_EDIT: `${API_URL}/master-rural/district-union-ward/edit/`,
  MASTER_DISTRICT_PANCHAYAT_UNION_WARD_DELETE: `${API_URL}/master-rural/district-union-ward/delete`,
  MASTER_DISTRICT_PANCHAYAT_UNION_WARD_UPLOAD_FILE: `${API_URL}/master-rural/district-union-ward/upload`,
  MASTER_DISTRICT_PANCHAYAT_UNION_WARD_READ_FILE: `${API_URL}/master-rural/district-union-ward/read`,
  MASTER_DISTRICT_PANCHAYAT_UNION_WARD_ALL_LIST: `${API_URL}/master-rural/district-union-ward/list`,
  MASTER_DISTRICT_PANCHAYAT_UNION_WARD_EXPORT: `${API_URL}/master-rural/district-union-ward/export-csv`,
  MASTER_DISTRICT_PANCHAYAT_UNION_WARD_DISTRICT_LIST: `${API_URL}/master-rural/district-union-ward/district/`,
  MASTER_DISTRICT_PANCHAYAT_UNION_WARD_DISTRICT_UNION_LIST: `${API_URL}/master-rural/district-union-ward/district-union/`,
  MASTER_DISTRICT_PANCHAYAT_UNION_WARD_BY_DISTRICT_UNIONS_LIST: `${API_URL}/master-rural/district-union-ward/district-unions`,

  MASTER_PANCHAYAT_UNION_LIST: `${API_URL}/master-rural/panchayat-union`,
  MASTER_PANCHAYAT_UNION_CREATE: `${API_URL}/master-rural/panchayat-union/create`,
  MASTER_PANCHAYAT_UNION_EDIT: `${API_URL}/master-rural/panchayat-union/edit/`,
  MASTER_PANCHAYAT_UNION_DELETE: `${API_URL}/master-rural/panchayat-union/delete`,
  MASTER_PANCHAYAT_UNION_UPLOAD_FILE: `${API_URL}/master-rural/panchayat-union/upload`,
  MASTER_PANCHAYAT_UNION_READ_FILE: `${API_URL}/master-rural/panchayat-union/read`,
  MASTER_PANCHAYAT_UNION_ALL_LIST: `${API_URL}/master-rural/panchayat-union/list`,
  MASTER_PANCHAYAT_UNION_EXPORT: `${API_URL}/master-rural/panchayat-union/export-csv`,
  MASTER_PANCHAYAT_UNION_DISTRICT_UNION_LIST: `${API_URL}/master-rural/panchayat-union/district-union/`,
  MASTER_PANCHAYAT_UNION_DISTRICT_LIST: `${API_URL}/master-rural/panchayat-union/district/`,

  MASTER_PANCHAYAT_UNION_WARD_LIST: `${API_URL}/master-rural/panchayat-union-ward`,
  MASTER_PANCHAYAT_UNION_WARD_CREATE: `${API_URL}/master-rural/panchayat-union-ward/create`,
  MASTER_PANCHAYAT_UNION_WARD_EDIT: `${API_URL}/master-rural/panchayat-union-ward/edit/`,
  MASTER_PANCHAYAT_UNION_WARD_DELETE: `${API_URL}/master-rural/panchayat-union-ward/delete`,
  MASTER_PANCHAYAT_UNION_WARD_UPLOAD_FILE: `${API_URL}/master-rural/panchayat-union-ward/upload`,
  MASTER_PANCHAYAT_UNION_WARD_READ_FILE: `${API_URL}/master-rural/panchayat-union-ward/read`,
  MASTER_PANCHAYAT_UNION_WARD_ALL_LIST: `${API_URL}/master-rural/panchayat-union-ward/list`,
  MASTER_PANCHAYAT_UNION_WARD_EXPORT: `${API_URL}/master-rural/panchayat-union-ward/export-csv`,
  MASTER_PANCHAYAT_UNION_WARD_DISTRICT_UNION_LIST: `${API_URL}/master-rural/panchayat-union-ward/district-union/`,
  MASTER_PANCHAYAT_UNION_WARD_PANCHAYAT_UNION_LIST: `${API_URL}/master-rural/panchayat-union-ward/panchayat-union/`,
  MASTER_PANCHAYAT_UNION_WARD_BY_PANCHAYAT_UNIONS_LIST: `${API_URL}/master-rural/panchayat-union-ward/panchayat-unions`,

  MASTER_VILLAGE_PANCHAYAT_LIST: `${API_URL}/master-rural/village-panchayat`,
  MASTER_VILLAGE_PANCHAYAT_CREATE: `${API_URL}/master-rural/village-panchayat/create`,
  MASTER_VILLAGE_PANCHAYAT_EDIT: `${API_URL}/master-rural/village-panchayat/edit/`,
  MASTER_VILLAGE_PANCHAYAT_DELETE: `${API_URL}/master-rural/village-panchayat/delete`,
  MASTER_VILLAGE_PANCHAYAT_UPLOAD_FILE: `${API_URL}/master-rural/village-panchayat/upload`,
  MASTER_VILLAGE_PANCHAYAT_READ_FILE: `${API_URL}/master-rural/village-panchayat/read`,
  MASTER_VILLAGE_PANCHAYAT_ALL_LIST: `${API_URL}/master-rural/village-panchayat/list`,
  MASTER_VILLAGE_PANCHAYAT_EXPORT: `${API_URL}/master-rural/village-panchayat/export-csv`,
  MASTER_VILLAGE_PANCHAYAT_DISTRICT_LIST: `${API_URL}/master-rural/village-panchayat/district/`,
  MASTER_VILLAGE_PANCHAYAT_PANCHAYAT_UNION_LIST: `${API_URL}/master-rural/village-panchayat/panchayat-union/`,

  MASTER_VILLAGE_PANCHAYAT_WARD_LIST: `${API_URL}/master-rural/village-panchayat-ward`,
  MASTER_VILLAGE_PANCHAYAT_WARD_CREATE: `${API_URL}/master-rural/village-panchayat-ward/create`,
  MASTER_VILLAGE_PANCHAYAT_WARD_EDIT: `${API_URL}/master-rural/village-panchayat-ward/edit/`,
  MASTER_VILLAGE_PANCHAYAT_WARD_DELETE: `${API_URL}/master-rural/village-panchayat-ward/delete`,
  MASTER_VILLAGE_PANCHAYAT_WARD_UPLOAD_FILE: `${API_URL}/master-rural/village-panchayat-ward/upload`,
  MASTER_VILLAGE_PANCHAYAT_WARD_READ_FILE: `${API_URL}/master-rural/village-panchayat-ward/read`,
  MASTER_VILLAGE_PANCHAYAT_WARD_ALL_LIST: `${API_URL}/master-rural/village-panchayat-ward/list`,
  MASTER_VILLAGE_PANCHAYAT_WARD_EXPORT: `${API_URL}/master-rural/village-panchayat-ward/export-csv`,
  MASTER_VILLAGE_PANCHAYAT_WARD_DISTRICT_LIST: `${API_URL}/master-rural/village-panchayat-ward/district/`,
  MASTER_VILLAGE_PANCHAYAT_WARD_VILLAGE_PANCHAYAT_LIST: `${API_URL}/master-rural/village-panchayat-ward/village-panchayats`,

  ELECTION_LIST: `${API_URL}/election`,
  ELECTION_CREATE: `${API_URL}/election/create`,
  ELECTION_EDIT: `${API_URL}/election/edit/`,
  ELECTION_DELETE: `${API_URL}/election/delete`,
  ELECTION_ALL_LIST: `${API_URL}/election/list`,
  ELECTION_LIST_BY_STATENAME: `${API_URL}/election/list/state-name`,
  ELECTION_FILES_UPLOAD: `${API_URL}/election/upload/election-files`,
  ELECTION_LIST_BY_CATEGORY: `${API_URL}/election/list/category`,

  // INFO: URBAN ELECTION
  ELECTION_URBAN_LIST: `${API_URL}/election-urban`,
  ELECTION_URBAN_CREATE: `${API_URL}/election-urban/create`,
  ELECTION_URBAN_EDIT: `${API_URL}/election-urban/edit/`,
  ELECTION_URBAN_DELETE: `${API_URL}/election-urban/delete`,

  CATALOG_STATES_LIST: `${API_URL}/catalog/states/`,
  CATALOG_STATES_CREATE: `${API_URL}/catalog/states/create/`,
  CATALOG_STATES_EDIT: `${API_URL}/catalog/states/edit/`,
  CATALOG_STATES_READ_FILE: `${API_URL}/catalog/states/read-state/`,
  CATALOG_STATES_DELETE: `${API_URL}/catalog/states/delete`,
  CATALOG_STATES_ALL_LIST: `${API_URL}/catalog/states/all/list/`,
  CATALOG_STATES_EXPORT: `${API_URL}/catalog/states/export-csv/`,
  CATALOG_ELECTION_STATES_LIST: `${API_URL}/catalog/states/election/list/`,

  CATALOG_DISTRICTS_LIST: `${API_URL}/catalog/districts/`,
  CATALOG_DISTRICTS_CREATE: `${API_URL}/catalog/districts/create/`,
  CATALOG_DISTRICTS_EDIT: `${API_URL}/catalog/districts/edit/`,
  CATALOG_DISTRICTS_READ_FILE: `${API_URL}/catalog/districts/read-district/`,
  CATALOG_DISTRICTS_DELETE: `${API_URL}/catalog/districts/delete`,
  CATALOG_DISTRICTS_ALL_LIST: `${API_URL}/catalog/districts/all/list/`,
  CATALOG_DISTRICTS_STATE_DISTRICT_LIST: `${API_URL}/catalog/districts/district-list/`,
  CATALOG_DISTRICTS_EXPORT: `${API_URL}/catalog/districts/export-csv/`,

  CATALOG_PC_LIST: `${API_URL}/catalog/pc/`,
  CATALOG_PC_CREATE: `${API_URL}/catalog/pc/create/`,
  CATALOG_PC_EDIT: `${API_URL}/catalog/pc/edit/`,
  CATALOG_PC_READ_FILE: `${API_URL}/catalog/pc/read-pc/`,
  CATALOG_PC_DELETE: `${API_URL}/catalog/pc/delete`,
  CATALOG_PC_ALL_LIST: `${API_URL}/catalog/pc/all/list/`,
  CATALOG_STATE_PC_LIST: `${API_URL}/catalog/pc/pc-list/`,
  CATALOG_PC_EXPORT: `${API_URL}/catalog/pc/export-csv/`,

  CATALOG_AC_LIST: `${API_URL}/catalog/ac/`,
  CATALOG_AC_CREATE: `${API_URL}/catalog/ac/create/`,
  CATALOG_AC_EDIT: `${API_URL}/catalog/ac/edit/`,
  CATALOG_AC_READ_FILE: `${API_URL}/catalog/ac/read-ac/`,
  CATALOG_AC_DELETE: `${API_URL}/catalog/ac/delete`,
  CATALOG_AC_ALL_LIST: `${API_URL}/catalog/ac/all/list/`,
  CATALOG_PC_AC_LIST: `${API_URL}/catalog/ac/ac-pc-list/`,
  CATALOG_STATE_AC_LIST: `${API_URL}/catalog/ac/ac-state-list/`,
  CATALOG_DISTRICT_AC_LIST: `${API_URL}/catalog/ac/ac-district-list/`,
  CATALOG_AC_EXPORT: `${API_URL}/catalog/ac/export-csv/`,
  CATALOG_AC_VOTER_DELETE_ALL: `${API_URL}/catalog/ac/clear`,
  CATALOG_AC_RESERVATION_LIST: `${API_URL}/catalog/ac/reservation/list/`,

  CATALOG_CANDIDATE_LIST: `${API_URL}/catalog/candidate/`,
  CATALOG_CANDIDATE_PC_LIST: `${API_URL}/catalog/candidate/candidate-pc-list/`,
  CATALOG_CANDIDATE_AC_LIST: `${API_URL}/catalog/candidate/candidate-ac-list/`,
  CATALOG_CANDIDATE_CREATE: `${API_URL}/catalog/candidate/create/`,
  CATALOG_CANDIDATE_EDIT: `${API_URL}/catalog/candidate/edit/`,
  CATALOG_CANDIDATE_DELETE: `${API_URL}/catalog/candidate/delete`,
  CATALOG_CANDIDATE_UPLOAD_FILE: `${API_URL}/catalog/candidate/upload-candidate`,
  CATALOG_CANDIDATE_READ_FILE: `${API_URL}/catalog/candidate/read-candidate/`,
  CATALOG_CANDIDATE_STATUS_TOGGLE: `${API_URL}/catalog/candidate/status-toggle`,
  CATALOG_CANDIDATE_EXPORT: `${API_URL}/catalog/candidate/export-csv/`,
  CANDIDATE_IMAGE_UPLOAD: `${API_URL}/catalog/candidate/upload-image`,
  CANDIDATE_IMAGE_DELETE: `${API_URL}/catalog/candidate/delete/candidate-image`,
  CANDIDATE_AFFIDAVIT_UPLOAD: `${API_URL}/catalog/candidate/upload-affidavit`,
  CANDIDATE_AFFIDAVIT_DELETE: `${API_URL}/catalog/candidate/delete/affidavit`,
  CRM_CANDIDATE_LIST: `${API_URL}/catalog/candidate/crm/list/all/candidate`,
  CRM_CANDIDATE_EXPORT: `${API_URL}/catalog/candidate/crm/download/all/candidate`,
  CATALOG_CANDIDATE_WHATSAPP_RESPONSE_TOGGLE: `${API_URL}/catalog/candidate/whatsapp/toggle`,
  CATALOG_ELECTED_CANDIDATE: `${API_URL}/catalog/candidate/elected-candidate/`,
  CATALOG_CANDIDATE_CREATE_DATA_DOWNLOAD_REQUEST: `${API_URL}/catalog/candidate/create/data/download-request`,
  CATALOG_CANDIDATE_BY_AC_ID_LIST: `${API_URL}/catalog/candidate/ac-list/`,

  CATALOG_AC_BOOTH_LIST: `${API_URL}/catalog/ac-booth/`,
  CATALOG_AC_BOOTH_CREATE: `${API_URL}/catalog/ac-booth/create/`,
  CATALOG_AC_BOOTH_EDIT: `${API_URL}/catalog/ac-booth/edit/`,
  CATALOG_AC_BOOTH_DELETE: `${API_URL}/catalog/ac-booth/delete`,
  CATALOG_AC_BOOTH_UPLOAD_FILE: `${API_URL}/catalog/ac-booth/upload-ac-booth`,
  CATALOG_AC_BOOTH_READ_FILE: `${API_URL}/catalog/ac-booth/read-ac-booth/`,
  CATALOG_AC_BOOTH_ALL_LIST: `${API_URL}/catalog/ac-booth/all/list/`,
  CATALOG_AC_BOOTH_EXPORT: `${API_URL}/catalog/ac-booth/export-csv/`,
  CATALOG_AC_BOOTH_AC_LIST: `${API_URL}/catalog/ac-booth/ac/`,
  CATALOG_AC_BOOTH_AC_IDS: `${API_URL}/catalog/ac-booth/ac/list/`,
  CATALOG_AC_BOOTH_VOTERS: `${API_URL}/catalog/ac-booth/voters-list/`,

  CATALOG_AC_VOTER_LIST: `${API_URL}/catalog/ac-voter/`,
  CATALOG_AC_VOTER_CREATE: `${API_URL}/catalog/ac-voter/create/`,
  CATALOG_AC_VOTER_EDIT: `${API_URL}/catalog/ac-voter/edit/`,
  CATALOG_AC_VOTER_DELETE: `${API_URL}/catalog/ac-voter/delete`,
  CATALOG_AC_VOTER_UPLOAD_FILE: `${API_URL}/catalog/ac-voter/upload-ac-voter`,
  CATALOG_AC_VOTER_READ_FILE: `${API_URL}/catalog/ac-voter/read-ac-voter/`,
  CATALOG_AC_VOTER_ALL_LIST: `${API_URL}/catalog/ac-voter/list`,
  CATALOG_AC_VOTER_EXPORT: `${API_URL}/catalog/ac-voter/export-csv/`,
  CATALOG_AC_VOTER_CREATE_DATA_DOWNLOAD_REQUEST: `${API_URL}/catalog/ac-voter/create/download-request/`,
  CATALOG_AC_VOTER_TEMPLATE_DOWNLOAD: `${API_URL}/catalog/ac-voter/template/voters/download`,

  CATALOG_AC_SECTION_LIST: `${API_URL}/catalog/ac-section/`,
  CATALOG_AC_SECTION_CREATE: `${API_URL}/catalog/ac-section/create/`,
  CATALOG_AC_SECTION_EDIT: `${API_URL}/catalog/ac-section/edit/`,
  CATALOG_AC_SECTION_DELETE: `${API_URL}/catalog/ac-section/delete`,
  CATALOG_AC_SECTION_UPLOAD_FILE: `${API_URL}/catalog/ac-section/upload-ac-section`,
  CATALOG_AC_SECTION_READ_FILE: `${API_URL}/catalog/ac-section/read-ac-section/`,
  CATALOG_AC_SECTION_ALL_LIST: `${API_URL}/catalog/ac-section/list/`,
  CATALOG_AC_SECTION_EXPORT: `${API_URL}/catalog/ac-section/export-csv/`,
  CATALOG_AC_SECTION_BOOTH_SECTION_LIST: `${API_URL}/catalog/ac-section/ac-section-list/`,

  CATALOG_VOTER_FILE_UPLOAD_LIST: `${API_URL}/catalog/voter-file-upload-request/`,
  CATALOG_VOTER_FILE_UPLOAD_DELETE: `${API_URL}/catalog/voter-file-upload-request/delete`,

  VOTER_FIELDS_LIST: `${API_URL}/voter-field`,
  VOTER_FIELDS_CREATE: `${API_URL}/voter-field/create`,
  VOTER_FIELDS_EDIT: `${API_URL}/voter-field/edit/`,
  VOTER_FIELDS_DELETE: `${API_URL}/voter-field/delete`,
  VOTER_FIELDS_ALL_LIST: `${API_URL}/voter-field/list`,
  VOTER_FIELDS_STATUS_TOGGLE: `${API_URL}/voter-field/status-toggle`,

  PC_DASHBOARD: `${API_URL}/election/pc-dashboard/`,
  PC_DASHBOARD_TABLE_ONE: `${API_URL}/election/pc-dashboard/data/table1/`,
  PC_DASHBOARD_TABLE_TWO: `${API_URL}/election/pc-dashboard/data/table2/`,
  AC_DASHBOARD: `${API_URL}/election/ac-dashboard/`,
  AC_DASHBOARD_TABLE_ONE: `${API_URL}/election/ac-dashboard/data/table1/`,
  AC_DASHBOARD_TABLE_TWO: `${API_URL}/election/ac-dashboard/data/table2/`,

  REFRESH_CATALOG_COUNT_LIST: `${API_URL}/refresh-catalog-count`,
  REFRESH_CATALOG_COUNT_CREATE: `${API_URL}/refresh-catalog-count/create`,

  DATA_DOWNLOAD_REQUESTS_LIST: `${API_URL}/data-download-requests`,

  VERSION_LIST: `${API_URL}/version`,
  VERSION_CREATE: `${API_URL}/version/create`,
  VERSION_EDIT: `${API_URL}/version/edit/`,
  VERSION_DELETE: `${API_URL}/version/delete`,

  POLITICAL_PARTY_LIST: `${API_URL}/political-party`,
  POLITICAL_PARTY_CREATE: `${API_URL}/political-party/create`,
  POLITICAL_PARTY_EDIT: `${API_URL}/political-party/edit/`,
  POLITICAL_PARTY_DELETE: `${API_URL}/political-party/delete`,
  POLITICAL_PARTY_ALL_LIST: `${API_URL}/political-party/list`,
  POLITICAL_PARTY_LOGO: `${API_URL}/political-party/upload-logo`,
  POLITICAL_PARTY_BY_STATE: `${API_URL}/political-party/state/list/`,
  POLITICAL_PARTY_EXPORT: `${API_URL}/political-party/export-csv`,
  POLITICAL_PARTY_READ_FILE: `${API_URL}/political-party/read-parties`,
  POLITICAL_PARTY_UPLOAD_FILE: `${API_URL}/political-party/upload-parties`,

  BUG_LIST: `${API_URL}/bug-tracker`,
  BUG_CREATE: `${API_URL}/bug-tracker/create`,
  BUG_EDIT: `${API_URL}/bug-tracker/edit/`,
  BUG_DELETE: `${API_URL}/bug-tracker/delete`,
  BUG_UPLOAD: `${API_URL}/bug-tracker/upload-files`,
  BUG_STATUS_LIST: `${API_URL}/bug-tracker/list/bugs-by-status/`,
  BUG_VERSION_LIST: `${API_URL}/bug-tracker/list/bugs-by-versionId/`,
  BUG_EXPORT: `${API_URL}/bug-tracker/export-csv`,
  BUG_TRACKER_STATUS_TOGGLE: `${API_URL}/bug-tracker/status-toggle`,

  LEADS_LIST: `${API_URL}/crm/leads`,
  LEADS_CREATE: `${API_URL}/crm/leads/create`,
  LEADS_EDIT: `${API_URL}/crm/leads/edit/`,
  LEADS_DELETE: `${API_URL}/crm/leads/delete`,
  LEADS_EXPORT: `${API_URL}/crm/leads/export-csv`,
  LEADS_READ_FILE: `${API_URL}/crm/leads/read-leads`,
  LEADS_UPLOAD_FILE: `${API_URL}/crm/leads/upload-leads`,

  VENDORS_LIST: `${API_URL}/crm/vendors`,
  VENDORS_CREATE: `${API_URL}/crm/vendors/create`,
  VENDORS_EDIT: `${API_URL}/crm/vendors/edit/`,
  VENDORS_DELETE: `${API_URL}/crm/vendors/delete`,
  VENDORS_EXPORT: `${API_URL}/crm/vendors/export-csv`,
  VENDORS_READ_FILE: `${API_URL}/crm/vendors/read-vendors`,
  VENDORS_UPLOAD_FILE: `${API_URL}/crm/vendors/upload-vendors`,

  SYMBOLS_LIST: `${API_URL}/manage/symbols`,
  SYMBOLS_CREATE: `${API_URL}/manage/symbols/create`,
  SYMBOLS_EDIT: `${API_URL}/manage/symbols/edit/`,
  SYMBOLS_DELETE: `${API_URL}/manage/symbols/delete`,
  SYMBOLS_ALL_LIST: `${API_URL}/manage/symbols/list`,

  MASTER_STATES_DASHBOARD: `${API_URL}/dashboard/master-states`,
  MASTER_DISTRICTS_DASHBOARD: `${API_URL}/dashboard/master-districts`,
  MASTER_PCS_DASHBOARD: `${API_URL}/dashboard/master-pcs`,
  MASTER_ACS_DASHBOARD: `${API_URL}/dashboard/master-acs`,
  CATALOG_CANDIDATE_DASHBOARD: `${API_URL}/dashboard/candidate-dashboard`,

  BUG_COMMENTS_LIST: `${API_URL}/bug-comments`,
  BUG_COMMENTS_CREATE: `${API_URL}/bug-comments/create`,

  BULK_WHATSAPP_LIST: `${API_URL}/campaign/bulk-whatsapp`,
  BULK_WHATSAPP_CREATE: `${API_URL}/campaign/bulk-whatsapp/create`,
  BULK_WHATSAPP_EDIT: `${API_URL}/campaign/bulk-whatsapp/edit/`,
  BULK_WHATSAPP_DELETE: `${API_URL}/campaign/bulk-whatsapp/delete`,
  BULK_WHATSAPP_STATUS_TOGGLE: `${API_URL}/campaign/bulk-whatsapp/status-toggle`,
  BULK_WHATSAPP_EXPORT: `${API_URL}/campaign/bulk-whatsapp/export-csv`,
  BULK_WHATSAPP_ENTRIES_LIST: `${API_URL}/campaign/bulk-whatsapp/list/entries-by-bulk-whatsapp-id/`,

  BULK_VOICE_CALL_LIST: `${API_URL}/campaign/bulk-voice-call`,
  BULK_VOICE_CALL_CREATE: `${API_URL}/campaign/bulk-voice-call/create`,
  BULK_VOICE_CALL_EDIT: `${API_URL}/campaign/bulk-voice-call/edit/`,
  BULK_VOICE_CALL_DELETE: `${API_URL}/campaign/bulk-voice-call/delete`,
  BULK_VOICE_CALL_STATUS_TOGGLE: `${API_URL}/campaign/bulk-voice-call/status-toggle`,
  BULK_VOICE_CALL_EXPORT: `${API_URL}/campaign/bulk-voice-call/export-csv`,
  BULK_VOICE_CALL_ENTRIES_LIST: `${API_URL}/campaign/bulk-voice-call/list/entries-by-bulk-voice-call-id/`,

  BULK_MESSAGE_LIST: `${API_URL}/campaign/bulk-message`,
  BULK_MESSAGE_CREATE: `${API_URL}/campaign/bulk-message/create`,
  BULK_MESSAGE_EDIT: `${API_URL}/campaign/bulk-message/edit/`,
  BULK_MESSAGE_DELETE: `${API_URL}/campaign/bulk-message/delete`,
  BULK_MESSAGE_STATUS_TOGGLE: `${API_URL}/campaign/bulk-message/status-toggle`,
  BULK_MESSAGE_EXPORT: `${API_URL}/campaign/bulk-message/export-csv`,
  BULK_MESSAGE_ENTRIES_LIST: `${API_URL}/campaign/bulk-message/list/entries-by-bulk-message-id/`,

  // INFO: COMMON API'S
  FILE_UPLOAD: `${API_URL}/upload/file`,
  IMAGE_UPLOAD: `${API_URL}/upload/image-file`,
  AUDIO_FILE_UPLOAD: `${API_URL}/upload/audio-file`,

  WATI_TEMPLATES: `${API_URL}/wati/templates`,

  // INFO: CATALOG URBAN

  CATALOG_URBAN_STATES_LIST: `${API_URL}/catalog-urban/states/`,
  CATALOG_URBAN_STATES_CREATE: `${API_URL}/catalog-urban/states/create/`,
  CATALOG_URBAN_STATES_EDIT: `${API_URL}/catalog-urban/states/edit/`,
  CATALOG_URBAN_STATES_READ_FILE: `${API_URL}/catalog-urban/states/read-state/`,
  CATALOG_URBAN_STATES_DELETE: `${API_URL}/catalog-urban/states/delete`,
  CATALOG_URBAN_STATES_ALL_LIST: `${API_URL}/catalog-urban/states/all/list/`,
  CATALOG_URBAN_STATES_EXPORT: `${API_URL}/catalog-urban/states/export-csv/`,
  CATALOG_URBAN_ELECTION_STATES_LIST: `${API_URL}/catalog-urban/states/election/list/`,

  CATALOG_URBAN_DISTRICTS_LIST: `${API_URL}/catalog-urban/districts/`,
  CATALOG_URBAN_DISTRICTS_CREATE: `${API_URL}/catalog-urban/districts/create/`,
  CATALOG_URBAN_DISTRICTS_EDIT: `${API_URL}/catalog-urban/districts/edit/`,
  CATALOG_URBAN_DISTRICTS_READ_FILE: `${API_URL}/catalog-urban/districts/read-district/`,
  CATALOG_URBAN_DISTRICTS_DELETE: `${API_URL}/catalog-urban/districts/delete`,
  CATALOG_URBAN_DISTRICTS_ALL_LIST: `${API_URL}/catalog-urban/districts/all/list/`,
  CATALOG_URBAN_DISTRICTS_STATE_DISTRICT_LIST: `${API_URL}/catalog-urban/districts/district-list/`,
  CATALOG_URBAN_DISTRICTS_EXPORT: `${API_URL}/catalog-urban/districts/export-csv/`,

  CATALOG_URBAN_PC_LIST: `${API_URL}/catalog-urban/pc/`,
  CATALOG_URBAN_PC_CREATE: `${API_URL}/catalog-urban/pc/create/`,
  CATALOG_URBAN_PC_EDIT: `${API_URL}/catalog-urban/pc/edit/`,
  CATALOG_URBAN_PC_DELETE: `${API_URL}/catalog-urban/pc/delete`,
  CATALOG_URBAN_PC_ALL_LIST: `${API_URL}/catalog-urban/pc/all/list/`,
  CATALOG_URBAN_STATE_PC_LIST: `${API_URL}/catalog-urban/pc/pc-list/`,
  CATALOG_URBAN_PC_EXPORT: `${API_URL}/catalog/pc-urban/export-csv/`,

  CATALOG_URBAN_AC_LIST: `${API_URL}/catalog-urban/ac/`,
  CATALOG_URBAN_AC_CREATE: `${API_URL}/catalog-urban/ac/create/`,
  CATALOG_URBAN_AC_EDIT: `${API_URL}/catalog-urban/ac/edit/`,
  CATALOG_URBAN_AC_READ_FILE: `${API_URL}/catalog-urban/ac/read-ac/`,
  CATALOG_URBAN_AC_DELETE: `${API_URL}/catalog-urban/ac/delete`,
  CATALOG_URBAN_AC_ALL_LIST: `${API_URL}/catalog-urban/ac/all/list/`,
  CATALOG_URBAN_PC_AC_LIST: `${API_URL}/catalog-urban/ac/ac-pc-list/`,
  CATALOG_URBAN_STATE_AC_LIST: `${API_URL}/catalog-urban/ac/ac-state-list/`,
  CATALOG_URBAN_DISTRICT_AC_LIST: `${API_URL}/catalog-urban/ac/ac-district-list/`,
  CATALOG_URBAN_AC_EXPORT: `${API_URL}/catalog-urban/ac/export-csv/`,
  CATALOG_URBAN_AC_VOTER_DELETE_ALL: `${API_URL}/catalog-urban/ac/clear`,
  CATALOG_URBAN_AC_RESERVATION_LIST: `${API_URL}/catalog-urban/ac/reservation/list/`,
  CATALOG_URBAN_ELECTION_AC_LIST: `${API_URL}/catalog-urban/ac/election-ac-list/`,

  CATALOG_CORPORATION_LIST: `${API_URL}/catalog-urban/corporation/`,
  CATALOG_CORPORATION_CREATE: `${API_URL}/catalog-urban/corporation/create`,
  CATALOG_CORPORATION_EDIT: `${API_URL}/catalog-urban/corporation/edit/`,
  CATALOG_CORPORATION_DELETE: `${API_URL}/catalog-urban/corporation/delete`,
  CATALOG_CORPORATION_ALL_LIST: `${API_URL}/catalog-urban/corporation/list`,
  CATALOG_CORPORATION_EXPORT: `${API_URL}/catalog-urban/corporation/export-csv`,
  CATALOG_CORPORATION_DISTRICT_LIST: `${API_URL}/catalog-urban/corporation/corporation-district-list/`,
  CATALOG_CORPORATION_STATE_LIST: `${API_URL}/catalog-urban/corporation/corporation-state-list/`,

  CATALOG_MUNICIPALITY_LIST: `${API_URL}/catalog-urban/municipality/`,
  CATALOG_MUNICIPALITY_CREATE: `${API_URL}/catalog-urban/municipality/create`,
  CATALOG_MUNICIPALITY_EDIT: `${API_URL}/catalog-urban/municipality/edit/`,
  CATALOG_MUNICIPALITY_DELETE: `${API_URL}/catalog-urban/municipality/delete`,
  CATALOG_MUNICIPALITY_ALL_LIST: `${API_URL}/catalog-urban/municipality/list`,
  CATALOG_MUNICIPALITY_EXPORT: `${API_URL}/catalog-urban/municipality/export-csv`,
  CATALOG_MUNICIPALITY_DISTRICT_LIST: `${API_URL}/catalog-urban/municipality/municipality-district-list/`,
  CATALOG_MUNICIPALITY_STATE_LIST: `${API_URL}/catalog-urban/municipality/municipality-state-list/`,

  CATALOG_TOWN_PANCHAYAT_LIST: `${API_URL}/catalog-urban/town-panchayat/`,
  CATALOG_TOWN_PANCHAYAT_CREATE: `${API_URL}/catalog-urban/town-panchayat/create`,
  CATALOG_TOWN_PANCHAYAT_EDIT: `${API_URL}/catalog-urban/town-panchayat/edit/`,
  CATALOG_TOWN_PANCHAYAT_DELETE: `${API_URL}/catalog-urban/town-panchayat/delete`,
  CATALOG_TOWN_PANCHAYAT_ALL_LIST: `${API_URL}/catalog-urban/town-panchayat/list`,
  CATALOG_TOWN_PANCHAYAT_EXPORT: `${API_URL}/catalog-urban/town-panchayat/export-csv`,
  CATALOG_TOWN_PANCHAYAT_DISTRICT_LIST: `${API_URL}/catalog-urban/town-panchayat/town-panchayat-district-list/`,
  CATALOG_TOWN_PANCHAYAT_STATE_LIST: `${API_URL}/catalog-urban/town-panchayat/town-panchayat-state-list/`,

  CATALOG_URBAN_WARD_LIST: `${API_URL}/catalog-urban/urban-ward/`,
  CATALOG_URBAN_WARD_CREATE: `${API_URL}/catalog-urban/urban-ward/create`,
  CATALOG_URBAN_WARD_EDIT: `${API_URL}/catalog-urban/urban-ward/edit/`,
  CATALOG_URBAN_WARD_DELETE: `${API_URL}/catalog-urban/urban-ward/delete`,
  CATALOG_URBAN_WARD_ALL_LIST: `${API_URL}/catalog-urban/urban-ward/list`,
  CATALOG_URBAN_WARD_EXPORT: `${API_URL}/catalog-urban/urban-ward/export-csv`,
  CATALOG_URBAN_WARD_CORPORATION_LIST: `${API_URL}/catalog-urban/urban-ward/corporation-ward-list`,
  CATALOG_URBAN_WARD_MUNICIPALITY_LIST: `${API_URL}/catalog-urban/urban-ward/municipality-ward-list`,
  CATALOG_URBAN_WARD_TOWN_PANCHAYAT_LIST: `${API_URL}/catalog-urban/urban-ward/town-panchayat-ward-list`,
  CATALOG_URBAN_WARD_DISTRICT_LIST: `${API_URL}/catalog-urban/urban-ward/district-list/`,
  CATALOG_URBAN_WARD_VOTER_DELETE_ALL: `${API_URL}/catalog-urban/urban-ward/clear`,
  CATALOG_URBAN_WARD_AC_LIST: `${API_URL}/catalog-urban/urban-ward/ac-list/`,
  CATALOG_URBAN_WARD_ELECTION_LIST: `${API_URL}/catalog-urban/urban-ward/election-list/`,

  CATALOG_URBAN_WARD_CANDIDATE_LIST: `${API_URL}/catalog-urban/urban-ward-candidate/`,
  CATALOG_URBAN_WARD_CANDIDATE_CREATE: `${API_URL}/catalog-urban/urban-ward-candidate/create/`,
  CATALOG_URBAN_WARD_CANDIDATE_EDIT: `${API_URL}/catalog-urban/urban-ward-candidate/edit/`,
  CATALOG_URBAN_WARD_CANDIDATE_DELETE: `${API_URL}/catalog-urban/urban-ward-candidate/delete`,
  CATALOG_URBAN_WARD_CANDIDATE_READ_FILE: `${API_URL}/catalog-urban/urban-ward-candidate/read-candidate/`,
  CATALOG_URBAN_WARD_CANDIDATE_STATUS_TOGGLE: `${API_URL}/catalog-urban/urban-ward-candidate/status-toggle`,
  CATALOG_URBAN_WARD_CANDIDATE_EXPORT: `${API_URL}/catalog-urban/urban-ward-candidate/export-csv/`,
  CATALOG_URBAN_WARD_CRM_CANDIDATE_LIST: `${API_URL}/catalog-urban/urban-ward-candidate/crm/list/all/candidate`,
  CATALOG_URBAN_WARD_CRM_CANDIDATE_EXPORT: `${API_URL}/catalog-urban/urban-ward-candidate/crm/download/all/candidate`,
  CATALOG_URBAN_WARD_CANDIDATE_WHATSAPP_RESPONSE_TOGGLE: `${API_URL}/catalog-urban/urban-ward-candidate/whatsapp/toggle`,
  CATALOG_URBAN_WARD_ELECTED_CANDIDATE: `${API_URL}/catalog-urban/urban-ward-candidate/elected-candidate/`,
  CATALOG_URBAN_WARD_CANDIDATE_CREATE_DATA_DOWNLOAD_REQUEST: `${API_URL}/catalog-urban/urban-ward-candidate/create/data/download-request`,
  CATALOG_URBAN_WARD_CANDIDATE_URBAN_WARD_LIST: `${API_URL}/catalog-urban/urban-ward-candidate/urban-ward-candidate-list/`,

  CATALOG_URBAN_WARD_BOOTH_LIST: `${API_URL}/catalog-urban/urban-ward-booth/`,
  CATALOG_URBAN_WARD_BOOTH_CREATE: `${API_URL}/catalog-urban/urban-ward-booth/create/`,
  CATALOG_URBAN_WARD_BOOTH_EDIT: `${API_URL}/catalog-urban/urban-ward-booth/edit/`,
  CATALOG_URBAN_WARD_BOOTH_DELETE: `${API_URL}/catalog-urban/urban-ward-booth/delete`,
  CATALOG_URBAN_WARD_BOOTH_UPLOAD_FILE: `${API_URL}/catalog-urban/urban-ward-booth/upload-urban-ward-booth`,
  CATALOG_URBAN_WARD_BOOTH_READ_FILE: `${API_URL}/catalog-urban/urban-ward-booth/read-urban-ward-booth/`,
  CATALOG_URBAN_WARD_BOOTH_ALL_LIST: `${API_URL}/catalog-urban/urban-ward-booth/all/list/`,
  CATALOG_URBAN_WARD_BOOTH_EXPORT: `${API_URL}/catalog-urban/urban-ward-booth/export-csv/`,
  CATALOG_URBAN_WARD_BOOTH_URBAN_WARD_LIST: `${API_URL}/catalog-urban/urban-ward-booth/urban-ward/`,
  CATALOG_URBAN_WARD_BOOTH_URBAN_WARD_IDS: `${API_URL}/catalog-urban/urban-ward-booth/urban-ward/list/`,

  CATALOG_URBAN_WARD_VOTER_LIST: `${API_URL}/catalog-urban/urban-ward-voter/`,
  CATALOG_URBAN_WARD_VOTER_CREATE: `${API_URL}/catalog-urban/urban-ward-voter/create/`,
  CATALOG_URBAN_WARD_VOTER_EDIT: `${API_URL}/catalog-urban/urban-ward-voter/edit/`,
  CATALOG_URBAN_WARD_VOTER_DELETE: `${API_URL}/catalog-urban/urban-ward-voter/delete`,
  CATALOG_URBAN_WARD_VOTER_UPLOAD_FILE: `${API_URL}/catalog-urban/urban-ward-voter/upload-ac-voter`,
  CATALOG_URBAN_WARD_VOTER_READ_FILE: `${API_URL}/catalog-urban/urban-ward-voter/read-urban-ward-voter/`,
  CATALOG_URBAN_WARD_VOTER_ALL_LIST: `${API_URL}/catalog-urban/urban-ward-voter/list`,
  CATALOG_URBAN_WARD_VOTER_EXPORT: `${API_URL}/catalog-urban/urban-ward-voter/export-csv/`,
  CATALOG_URBAN_WARD_VOTER_TEMPLATE_DOWNLOAD: `${API_URL}/catalog-urban/urban-ward-voter/template/download`,
  CATALOG_URBAN_WARD_VOTER_CREATE_DATA_DOWNLOAD_REQUEST: `${API_URL}/catalog-urban/urban-ward-voter/create/download-request/`,

  // INFO: RURAL ELECTION
  ELECTION_RURAL_LIST: `${API_URL}/election-rural`,
  ELECTION_RURAL_CREATE: `${API_URL}/election-rural/create`,
  ELECTION_RURAL_EDIT: `${API_URL}/election-rural/edit/`,
  ELECTION_RURAL_DELETE: `${API_URL}/election-rural/delete`,

  //INFO: CATALOG RURAL

  //INFO: STATES
  CATALOG_RURAL_STATES_LIST: `${API_URL}/catalog-rural/states/`,
  CATALOG_RURAL_STATES_CREATE: `${API_URL}/catalog-rural/states/create/`,
  CATALOG_RURAL_STATES_EDIT: `${API_URL}/catalog-rural/states/edit/`,
  CATALOG_RURAL_STATES_READ_FILE: `${API_URL}/catalog-rural/states/read-state/`,
  CATALOG_RURAL_STATES_DELETE: `${API_URL}/catalog-rural/states/delete`,
  CATALOG_RURAL_STATES_ALL_LIST: `${API_URL}/catalog-rural/states/all/list/`,
  CATALOG_RURAL_STATES_EXPORT: `${API_URL}/catalog-rural/states/export-csv/`,
  CATALOG_RURAL_ELECTION_STATES_LIST: `${API_URL}/catalog-rural/states/election/list/`,

  //INFO: DISTRICTS
  CATALOG_RURAL_DISTRICTS_LIST: `${API_URL}/catalog-rural/districts/`,
  CATALOG_RURAL_DISTRICTS_CREATE: `${API_URL}/catalog-rural/districts/create/`,
  CATALOG_RURAL_DISTRICTS_EDIT: `${API_URL}/catalog-rural/districts/edit/`,
  CATALOG_RURAL_DISTRICTS_READ_FILE: `${API_URL}/catalog-rural/districts/read-district/`,
  CATALOG_RURAL_DISTRICTS_DELETE: `${API_URL}/catalog-rural/districts/delete`,
  CATALOG_RURAL_DISTRICTS_ALL_LIST: `${API_URL}/catalog-rural/districts/all/list/`,
  CATALOG_RURAL_DISTRICTS_STATE_DISTRICT_LIST: `${API_URL}/catalog-rural/districts/district-list/`,
  CATALOG_RURAL_DISTRICTS_EXPORT: `${API_URL}/catalog-rural/districts/export-csv/`,
  CATALOG_RURAL_ELECTION_DISTRICTS_LIST: `${API_URL}/catalog-rural/districts/election-list/`,

  //INFO: DISTRICT UNION
  CATALOG_DISTRICT_PANCHAYAT_UNION_LIST: `${API_URL}/catalog-rural/district-union/`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_CREATE: `${API_URL}/catalog-rural/district-union/create`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_EDIT: `${API_URL}/catalog-rural/district-union/edit/`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_DELETE: `${API_URL}/catalog-rural/district-union/delete`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_UPLOAD_FILE: `${API_URL}/catalog-rural/district-union/upload`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_READ_FILE: `${API_URL}/catalog-rural/district-union/read`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_ALL_LIST: `${API_URL}/catalog-rural/district-union/list/`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_EXPORT: `${API_URL}/catalog-rural/district-union/export-csv/`,
  CATALOG_DISTRICT_UNION_DISTRICT_LIST: `${API_URL}/catalog-rural/district-union/district/`,

  //INFO: DISTRICT UNION WARD
  CATALOG_DISTRICT_PANCHAYAT_UNION_WARD_LIST: `${API_URL}/catalog-rural/district-union-ward/`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_WARD_CREATE: `${API_URL}/catalog-rural/district-union-ward/create`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_WARD_EDIT: `${API_URL}/catalog-rural/district-union-ward/edit/`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_WARD_DELETE: `${API_URL}/catalog-rural/district-union-ward/delete`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_WARD_ALL_LIST: `${API_URL}/catalog-rural/district-union-ward/list`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_WARD_EXPORT: `${API_URL}/catalog-rural/district-union-ward/export-csv/`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_WARD_DISTRICT_LIST: `${API_URL}/catalog-rural/district-union-ward/district/`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_WARD_DISTRICT_UNION_LIST: `${API_URL}/catalog-rural/district-union-ward/district-union/`,
  CATALOG_DISTRICT_PANCHAYAT_UNION_WARD_BY_DISTRICT_UNIONS_LIST: `${API_URL}/catalog-rural/district-union-ward/district-unions`,

  //INFO: PANCHAYAT UNION
  CATALOG_PANCHAYAT_UNION_LIST: `${API_URL}/catalog-rural/panchayat-union/`,
  CATALOG_PANCHAYAT_UNION_CREATE: `${API_URL}/catalog-rural/panchayat-union/create`,
  CATALOG_PANCHAYAT_UNION_EDIT: `${API_URL}/catalog-rural/panchayat-union/edit/`,
  CATALOG_PANCHAYAT_UNION_DELETE: `${API_URL}/catalog-rural/panchayat-union/delete`,
  CATALOG_PANCHAYAT_UNION_ALL_LIST: `${API_URL}/catalog-rural/panchayat-union/list/`,
  CATALOG_PANCHAYAT_UNION_EXPORT: `${API_URL}/catalog-rural/panchayat-union/export-csv/`,
  CATALOG_PANCHAYAT_UNION_DISTRICT_UNION_LIST: `${API_URL}/catalog-rural/panchayat-union/district-union/`,
  CATALOG_PANCHAYAT_UNION_DISTRICT_LIST: `${API_URL}/catalog-rural/panchayat-union/district/`,

  //INFO: PANCHAYAT UNION WARD
  CATALOG_PANCHAYAT_UNION_WARD_LIST: `${API_URL}/catalog-rural/panchayat-union-ward/`,
  CATALOG_PANCHAYAT_UNION_WARD_CREATE: `${API_URL}/catalog-rural/panchayat-union-ward/create`,
  CATALOG_PANCHAYAT_UNION_WARD_EDIT: `${API_URL}/catalog-rural/panchayat-union-ward/edit/`,
  CATALOG_PANCHAYAT_UNION_WARD_DELETE: `${API_URL}/catalog-rural/panchayat-union-ward/delete`,
  CATALOG_PANCHAYAT_UNION_WARD_ALL_LIST: `${API_URL}/catalog-rural/panchayat-union-ward/list/`,
  CATALOG_PANCHAYAT_UNION_WARD_EXPORT: `${API_URL}/catalog-rural/panchayat-union-ward/export-csv/`,
  CATALOG_PANCHAYAT_UNION_WARD_DISTRICT_UNION_LIST: `${API_URL}/catalog-rural/panchayat-union-ward/district-union/`,
  CATALOG_PANCHAYAT_UNION_WARD_PANCHAYAT_UNION_LIST: `${API_URL}/catalog-rural/panchayat-union-ward/panchayat-union/`,
  CATALOG_PANCHAYAT_UNION_WARD_BY_PANCHAYAT_UNIONS_LIST: `${API_URL}/catalog-rural/panchayat-union-ward/panchayat-unions`,
  CATALOG_PANCHAYAT_UNION_WARD_DISTRICT_LIST: `${API_URL}/catalog-rural/panchayat-union-ward/district/`,

  //INFO: VILLAGE PANCHAYAT
  CATALOG_VILLAGE_PANCHAYAT_LIST: `${API_URL}/catalog-rural/village-panchayat/`,
  CATALOG_VILLAGE_PANCHAYAT_CREATE: `${API_URL}/catalog-rural/village-panchayat/create`,
  CATALOG_VILLAGE_PANCHAYAT_EDIT: `${API_URL}/catalog-rural/village-panchayat/edit/`,
  CATALOG_VILLAGE_PANCHAYAT_DELETE: `${API_URL}/catalog-rural/village-panchayat/delete`,
  CATALOG_VILLAGE_PANCHAYAT_ALL_LIST: `${API_URL}/catalog-rural/village-panchayat/list/`,
  CATALOG_VILLAGE_PANCHAYAT_EXPORT: `${API_URL}/catalog-rural/village-panchayat/export-csv/`,
  CATALOG_VILLAGE_PANCHAYAT_DISTRICT_LIST: `${API_URL}/catalog-rural/village-panchayat/district/`,
  CATALOG_VILLAGE_PANCHAYAT_PANCHAYAT_UNION_LIST: `${API_URL}/catalog-rural/village-panchayat/panchayat-union/`,

  //INFO: VILLAGE PANCHAYAT WARD
  CATALOG_VILLAGE_PANCHAYAT_WARD_LIST: `${API_URL}/catalog-rural/village-panchayat-ward/`,
  CATALOG_VILLAGE_PANCHAYAT_WARD_CREATE: `${API_URL}/catalog-rural/village-panchayat-ward/create`,
  CATALOG_VILLAGE_PANCHAYAT_WARD_EDIT: `${API_URL}/catalog-rural/village-panchayat-ward/edit/`,
  CATALOG_VILLAGE_PANCHAYAT_WARD_DELETE: `${API_URL}/catalog-rural/village-panchayat-ward/delete`,
  CATALOG_VILLAGE_PANCHAYAT_WARD_ALL_LIST: `${API_URL}/catalog-rural/village-panchayat-ward/list/`,
  CATALOG_VILLAGE_PANCHAYAT_WARD_EXPORT: `${API_URL}/catalog-rural/village-panchayat-ward/export-csv/`,
  CATALOG_VILLAGE_PANCHAYAT_WARD_DISTRICT_LIST: `${API_URL}/catalog-rural/village-panchayat-ward/district/`,
  CATALOG_VILLAGE_PANCHAYAT_WARD_VILLAGE_PANCHAYAT_LIST: `${API_URL}/catalog-rural/village-panchayat-ward/village-panchayats`,

  //INFO: RURAL WARD BOOTH APIS
  CATALOG_RURAL_WARD_BOOTH_LIST: `${API_URL}/catalog-rural/rural-ward-booth/`,
  CATALOG_RURAL_WARD_BOOTH_CREATE: `${API_URL}/catalog-rural/rural-ward-booth/create/`,
  CATALOG_RURAL_WARD_BOOTH_EDIT: `${API_URL}/catalog-rural/rural-ward-booth/edit/`,
  CATALOG_RURAL_WARD_BOOTH_DELETE: `${API_URL}/catalog-rural/rural-ward-booth/delete`,
  CATALOG_RURAL_WARD_BOOTH_UPLOAD_FILE: `${API_URL}/catalog-rural/rural-ward-booth/upload-rural-ward-booth`,
  CATALOG_RURAL_WARD_BOOTH_READ_FILE: `${API_URL}/catalog-rural/rural-ward-booth/read-rural-ward-booth/`,
  CATALOG_RURAL_WARD_BOOTH_ALL_LIST: `${API_URL}/catalog-rural/rural-ward-booth/all/list/`,
  CATALOG_RURAL_WARD_BOOTH_EXPORT: `${API_URL}/catalog-rural/rural-ward-booth/export-csv/`,
  CATALOG_RURAL_WARD_BOOTH_RURAL_WARD_LIST: `${API_URL}/catalog-rural/rural-ward-booth/rural-ward/`,
  CATALOG_RURAL_WARD_BOOTH_RURAL_WARD_IDS: `${API_URL}/catalog-rural/rural-ward-booth/rural-ward/list/`,

  //INFO: RURAL CANDIDATE
  CATALOG_RURAL_WARD_CANDIDATE_LIST: `${API_URL}/catalog-rural/rural-ward-candidate/`,
  CATALOG_RURAL_WARD_CANDIDATE_CREATE: `${API_URL}/catalog-rural/rural-ward-candidate/create/`,
  CATALOG_RURAL_WARD_CANDIDATE_EDIT: `${API_URL}/catalog-rural/rural-ward-candidate/edit/`,
  CATALOG_RURAL_WARD_CANDIDATE_DELETE: `${API_URL}/catalog-rural/rural-ward-candidate/delete`,
  CATALOG_RURAL_WARD_CANDIDATE_READ_FILE: `${API_URL}/catalog-rural/rural-ward-candidate/read-candidate/`,
  CATALOG_RURAL_WARD_CANDIDATE_STATUS_TOGGLE: `${API_URL}/catalog-rural/rural-ward-candidate/status-toggle`,
  CATALOG_RURAL_WARD_CANDIDATE_EXPORT: `${API_URL}/catalog-rural/rural-ward-candidate/export-csv/`,
  CATALOG_RURAL_WARD_CRM_CANDIDATE_LIST: `${API_URL}/catalog-rural/rural-ward-candidate/crm/list/all/candidate`,
  CATALOG_RURAL_WARD_CRM_CANDIDATE_EXPORT: `${API_URL}/catalog-rural/rural-ward-candidate/crm/download/all/candidate`,
  CATALOG_RURAL_WARD_CANDIDATE_WHATSAPP_RESPONSE_TOGGLE: `${API_URL}/catalog-rural/rural-ward-candidate/whatsapp/toggle`,
  CATALOG_RURAL_WARD_ELECTED_CANDIDATE: `${API_URL}/catalog-rural/rural-ward-candidate/elected-candidate/`,
  CATALOG_RURAL_WARD_CANDIDATE_CREATE_DATA_DOWNLOAD_REQUEST: `${API_URL}/catalog-rural/rural-ward-candidate/create/data/download-request`,
  CATALOG_RURAL_WARD_CANDIDATE_RURAL_WARD_LIST: `${API_URL}/catalog-rural/rural-ward-candidate/rural-ward-candidate-list/`,

  CATALOG_RURAL_WARD_VOTER_LIST: `${API_URL}/catalog-rural/rural-ward-voter/`,
  CATALOG_RURAL_WARD_VOTER_CREATE: `${API_URL}/catalog-rural/rural-ward-voter/create/`,
  CATALOG_RURAL_WARD_VOTER_EDIT: `${API_URL}/catalog-rural/rural-ward-voter/edit/`,
  CATALOG_RURAL_WARD_VOTER_DELETE: `${API_URL}/catalog-rural/rural-ward-voter/delete`,
  CATALOG_RURAL_WARD_VOTER_UPLOAD_FILE: `${API_URL}/catalog-rural/rural-ward-voter/upload-ac-voter`,
  CATALOG_RURAL_WARD_VOTER_READ_FILE: `${API_URL}/catalog-rural/rural-ward-voter/read-rural-ward-voter/`,
  CATALOG_RURAL_WARD_VOTER_ALL_LIST: `${API_URL}/catalog-rural/rural-ward-voter/list`,
  CATALOG_RURAL_WARD_VOTER_EXPORT: `${API_URL}/catalog-rural/rural-ward-voter/export-csv/`,
  CATALOG_RURAL_WARD_VOTER_TEMPLATE_DOWNLOAD: `${API_URL}/catalog-rural/rural-ward-voter/template/download`,
  CATALOG_RURAL_WARD_VOTER_CREATE_DATA_DOWNLOAD_REQUEST: `${API_URL}/catalog-rural/rural-ward-voter/create/download-request/`,
  CATALOG_RURAL_WARD_VOTER_DELETE_ALL: `${API_URL}/catalog-rural/rural-ward-voter/clear`,

  // INFO: MAP VOTER FIELD
  MAP_VOTER_FIELDS_LIST: `${API_URL}/map-voter-field/upload`,
};
